:root {
    --container-width: 15rem;
    --container-height: 15rem;
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}

.mainCard {
    width: 15rem;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

@-webkit-keyframes scale-and-fade {
    from {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 0);
        transform: scale3d(0, 0, 0);
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes scale-and-fade {
    from {
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale3d(0, 0, 0);
        transform: scale3d(0, 0, 0);
    }

    to {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.cardGroupWrapper {
    position: absolute;
    top: 40%;
    left: 50%;
    /* width: 60vw; */
    transform: translate(-50%, -50%);
    /* padding: 10px; */
}

.cardGroup {
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    position: relative;
    animation: 2.5s scale-and-fade 2s ease-in-out both;
}