.container {
  width: 15rem;
  height: 15rem;
  perspective: 1000px;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
}

.top {
  transform: rotateX(90deg) translateZ(7.5rem);
}

.bottom {
  transform: rotateX(-90deg) translateZ(7.5rem);
}

.right {
  transform: rotateY(90deg) translateZ(7.5rem);
}

.left {
  transform: rotateY(-90deg) translateZ(7.5rem);
}

.front {
  transform: rotateX(0deg) translateZ(7.5rem);
}

.back {
  transform: rotateX(-180deg) translateZ(7.5rem);
}

@keyframes spin-and-scale {
  from {
    visibility: visible;
    -webkit-transform: rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
    transform: rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
  }

  to {
    visibility: hidden;
    -webkit-transform: rotateX(360deg) rotateY(360deg) scale3d(0, 0, 0);
    transform: rotateX(360deg) rotateY(360deg) scale3d(0, 0, 0);
    display: none;
  }
}

@-webkit-keyframes spin-and-scale {
  from {
      visibility: visible;
      -webkit-transform: rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
      transform: rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
    }

    to {
      visibility: hidden;
      -webkit-transform: rotateX(360deg) rotateY(360deg) scale3d(0, 0, 0);
      transform: rotateX(360deg) rotateY(360deg) scale3d(0, 0, 0);
      display: none;
    }
}

.cube {
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-animation: 2s spin-and-scale 500ms ease-in-out both;
  animation: 2s spin-and-scale 500ms ease-in-out both;
}

