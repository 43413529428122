:root {
  --card-bg: grey !important;
}
.iconCard {
  padding: 0.1rem;
  cursor: pointer;
  /* background-color: var(--card-bg); */
  /* -webkit-animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) linear;
  animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) linear; */
/* -webkit-animation: fade-in 5s cubic-bezier(0.680, -0.550, 0.265, 1.550) alternate forwards; */
  /* animation: fade-in 5s cubic-bezier(0.680, -0.550, 0.265, 1.550) alternate forwards; */
}

.icon {
  font-size: 8rem;
  /* transform-style: preserve-3d; */
  color: rgb(11, 11, 11);
  -webkit-animation: 2s swirl-in-fwd 3s ease-in-out both;
  animation: 2s swirl-in-fwd 3s ease-in-out both;
}

.cubeIcon {
  font-size: 15rem;
  color: azure;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-11 15:58:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
    transform: rotate(-540deg) scale(0);
    /* opacity: 0; */
  }

  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    /* opacity: 1; */
  }
}

@keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
    transform: rotate(-540deg) scale(0);
    /* opacity: 0; */
  }

  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    /* opacity: 1; */
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-bck
 * ----------------------------------------
 */

@-webkit-keyframes swirl-in-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
    transform: rotate(540deg) scale(5);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

@keyframes swirl-in-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
    transform: rotate(540deg) scale(5);
    opacity: 0;
  }

  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}